html, body, #root{
  height: 100%;
  width: 100%;
}
body {
  background-color: #111111; /*#1B1A1A;*/
}

.my-layout-header {
  height: 40px!important;
  padding-inline: 0!important;
  color: rgba(255, 255, 255, 0.85);
  line-height: 40px!important;
  background: #000000!important;
}

.ant-layout-header a {
  color: #999999;
}

.ant-layout-content {
  background-color: #111111;
}

.logo-block {
  text-align: center;
  position: relative;
  margin: 10px 0;
}

.logo-line {
  border-top: 4px solid #333333;
  position: absolute;
  top: 58px;
  width: 100%;
}

.logo-content {
  background-color: #111111;
  z-index: 1;
  position: relative;
  width: 230px;
  display: inline-block;
  color: #e5e5e5;
}

.logo-content img {
  width: 190px;
  padding: 20px 0 15px;
}

.logo-content p {
  margin: 5px;
}

.tab-block {

}

.tab-img {
  width: 90px;
  border-radius: 50px;
  border: 4px solid #333;
}

.ant-tabs-tab-active .tab-img {
  /*border: 4px solid #fecd0f;*/
}
.ant-tabs-ink-bar {
  background-color: #fecd0f;
}
.ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 25px;
}

.content {
  max-width: 900px;
  margin: 0 auto;
  padding: 0 20px;
}

.button {
  background-color: #c72321;
  color: #fff;
  padding: 10px 20px;
  border-radius: 8px;
  font-weight: bold;
  font-size: 16px;
}
.button:hover {
  background-color: #9f1715;
  color: #fff;
}
.elfsight-app-f5feb33a-627c-4857-b401-a1900b283cef .eapps-link{
  display: none!important;
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #c72321;
  text-shadow: 0 0 0.25px currentcolor;
}
.ant-tabs .ant-tabs-tab-btn a{
  color: #c72321!important;
}
.ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible), .ant-tabs .ant-tabs-tab-remove:focus:not(:focus-visible), .ant-tabs .ant-tabs-tab-btn:active, .ant-tabs .ant-tabs-tab-remove:active {
  color: #c72321!important;
}
.ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible) a, .ant-tabs .ant-tabs-tab-remove:focus:not(:focus-visible) a, .ant-tabs .ant-tabs-tab-btn:active a, .ant-tabs .ant-tabs-tab-remove:active a {
  color: #c72321!important;
}
.ant-tabs .ant-tabs-ink-bar {
  position: absolute;
  background: #c72321;
  pointer-events: none;
}
.ant-tabs .ant-tabs-tab:hover {
  color: #ababab;
}


@media screen and (max-width: 500px) {
  .header-email{
    display: none;
  }
}